import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['button']
  static classes = ['active']

  perform() {
    this.buttonTargets.forEach(target => { target.classList.toggle(this.activeClass) })
  }
}
