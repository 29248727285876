import { Controller } from '@hotwired/stimulus'
import DataTable from "datatables.net";

export default class extends Controller {
  static targets = ['sortDescription', 'stripeColumn', 'table']

  connect() {
    this.datatable = new DataTable(this.tableTarget, {
      paging: false,
      order: [
        [
          this.data.get('default-sort'),
          'asc'
        ]
      ]
    })

    this.datatable.on('order', this.showSort.bind(this))
    this.showSort()
    this.showStripeColumnSelection()
  }

  disconnect() {
    this.datatable.destroy()
    this.datatable = undefined
  }

  showSort() {
    const order = this.datatable.order()
    const orderText = order.map(([columnIndex, direction]) => this.columnSortDescription(columnIndex, direction))
    this.sortDescriptionTarget.textContent = orderText.join(', ')
    this.stripeRows()
  }

  showStripeColumnSelection() {
    if (!this.hasStripeColumnTarget) { return }

    this.stripeColumnTarget.innerHTML = `<option value=''></option>`
    this.datatable.columns().every((colIndex) => {
      const colHeading = this.datatable.column(colIndex).header().innerText
      this.stripeColumnTarget.innerHTML += `<option value='${colIndex}'>${colHeading}</option>`
    })
  }

  stripeRows() {
    if (!this.hasStripeColumnTarget) { return }

    const columnIndex = this.stripeColumnTarget.value

    if (columnIndex == '') {
      // No striping if no column selected
      this.datatable.rows().every((rowIndex) => {
        this.datatable.row(rowIndex).node().classList = []
      })
    } else {
      let lastValue = null
      let stripe = 'even'

      // Change stripe for every row the column value changes
      this.datatable.rows().every((rowIndex) => {
        const currentValue = this.datatable.cell(rowIndex, columnIndex).node().innerText

        if (currentValue != lastValue) {
          stripe = stripe == 'even' ? 'odd' : 'even'
          lastValue = currentValue
        }

        this.datatable.row(rowIndex).node().classList = [`striped-${stripe}`]
      })
    }
  }

  columnSortDescription(index, direction) {
    const column = this.datatable.column(index)
    const name = column.header().textContent

    const directionText = direction === 'asc' ? 'ascending' : 'descending'

    return `${name} ${directionText}`
  }
}
