/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from '@rails/ujs'
import TurboLinks from 'turbolinks'
import 'bootstrap'
import 'select2'
import {Auth0Lock} from "auth0-lock"
window.Auth0Lock = Auth0Lock

Rails.start()
TurboLinks.start()
import $ from 'jquery'
window.$ = $
window.jQuery = $
import './controllers'
import './initializers/honeybadger'
