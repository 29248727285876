import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['output']

  insert(event) {
    event.preventDefault()
    const selectedItem = event.target
    this.outputTarget.value = selectedItem.dataset['itemValue']
    this.resizeTargetTextarea()
  }

  resizeTargetTextarea() {
    // Reset field height so we can calculate new height
    this.outputTarget.style.height = 'inherit'

    // Set a new height based on the textarea's content size
    const newHeight = this.outputTarget.scrollHeight
    this.outputTarget.style.height = `${newHeight}px`
  }
}
